import React from 'react';
import classnames from 'classnames';
import LogoWhite from 'images/common/logo_40th_white.svg';
import LogoBlack from 'images/common/logo_40th_black.svg';
import Link from '../Link';
import styles from './index.css';

interface LogoProperty {
	type?: 'white' | 'black';
	className?: string;
}

const Logo: React.FC<LogoProperty> = ({ type = 'white', className }) => {
	return (
		<Link className={classnames(styles.logo, className, styles[type])} to="/">
			{/* 由於客戶提供的 SVG 格式匯入 React 時會壞掉，因此改用 Condition Render 處理變色效果 */}
			{type === 'white' && <img src={LogoWhite} alt="" />}
			{type === 'black' && <img src={LogoBlack} alt="" />}
		</Link>
	);
};

export default Logo;
